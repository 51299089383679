#green {
    color: #008000;
}

#red {
    color: #ff0000;
}

#black {
    color: #000000;
}
#amber {
    color: #ffbf00;
}
#button {
    color: #ffffff;
    background-color: #000079;
    width: 100%;
    padding: 10px 20px;
    margin: 10px;
    font-size: 13px;
    text-align: center;
}
#button:hover {
    background-color: #2466ad;
    font-size: 13px;
}
