/* .downloadButton.MuiButton-contained {
    margin-top: 0.5em;
    background-color: #0033a0;
    float: right;
}
.downloadButton.MuiButton-contained:disabled {
    color: #ffffff;
    background-color: #a2a2a2;
} */

/* EvaluationTracker.css */

.container {
    margin-left: 20px;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    margin-bottom: 50px;
}

.testClass {
    margin-right: 20px;
}

.dropdown {
    margin-top: 30px;
    border: none;
}

.circularProgress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.tableContainer {
  max-height: 440;
  margin-top: 20px;
}
.table{
  border: none;
}